import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Toolbar,
} from "@mui/material";
import { logout } from "../../firebase";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Character } from "components/Character/Character";
import { useAuth } from "hooks/useAuth";
import { useCharacters } from "hooks/useCharacters";
import LogoutIcon from "@mui/icons-material/Logout";

export const Home = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    logout().then(() => navigate("/login"));
  };
  const [user, authLoading] = useAuth();
  const [characters, loading] = useCharacters();

  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <>
            {loading && <CircularProgress color="info" size={24} />}
            <Box sx={{ flexGrow: 1 }} />
            {loading || (
              <>
                <IconButton color="inherit" onClick={handleLogout}>
                  <LogoutIcon />
                </IconButton>
              </>
            )}
          </>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xs">
        <>
          {characters && (
            <>
              {characters
                .filter((c) => c !== undefined)
                .map((c) => (
                  <Character key={c.name} character={c} user={user} />
                ))}
            </>
          )}
        </>
      </Container>
    </>
  );
};
